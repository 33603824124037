import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import HeaderFilters from 'components/layout/Headers/Secondary/Filters'
import ContactIntro from 'components/layout/Sections/Contact/Intro'
import FAQSection from 'components/layout/Sections/Contact/FAQ'

import { CONTACT_LINKS } from 'constants/links'

import type { ContactFaqPageQuery } from 'types/graphql'
import type { Question } from 'components/layout/Sections/Contact/FAQ/SingleItem'

const ContactFAQ: React.FC<PageProps<ContactFaqPageQuery>> = ({ data }) => {
  const { page } = data

  const PAGE = page?.pageFaq
  const PAGE_SEO = page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const QUESTIONS: Question[] = useMemo(
    () =>
      PAGE.faqPageS3List?.map((item) => ({
        question: item?.faqPageS3ListElementTitle!,
        answer: item?.faqPageS3ListElementDescription!,
      })) || [],
    []
  )

  return (
    <Layout>
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Kontakt' }]} />
      <Header
        title={PAGE.faqPageS1Title!}
        image={{
          src: PAGE.faqPageS1Img?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE.faqPageS1Img?.altText!,
        }}
      />
      <main>
        <HeaderFilters options={CONTACT_LINKS} />
        <ContactIntro
          title={PAGE.faqPageS2Title!}
          description={PAGE.faqPageS2Description!}
          icon={PAGE.faqPageS2Img?.localFile?.publicURL!}
        />
        <FAQSection questions={QUESTIONS} />
      </main>
    </Layout>
  )
}

export default ContactFAQ

export const query = graphql`
  query ContactFAQPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/pytania/" }
    ) {
      seo {
        ...WpSEO
      }
      pageFaq {
        faqPageS1Title
        faqPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        faqPageS2Title
        faqPageS2Description
        faqPageS2Img {
          localFile {
            publicURL
          }
        }
        faqPageS3List {
          faqPageS3ListElementTitle
          faqPageS3ListElementDescription
        }
      }
    }
  }
`
