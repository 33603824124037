import React from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import { Text, Content } from 'components/shared/Typography'

import arrowDown from 'assets/icons/arrow-down.svg'

export type Question = {
  question: string
  answer: string
}

type Props = Question & {
  toggle: () => void
  active: boolean
}

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`

const Header = styled.div<{ active: boolean }>`
  position: relative;
  padding: 1.2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  & > ${Button} {
    pointer-events: none;
    border-width: 2px;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: none;
  }
  ${({ theme }) => theme.media.sm.max} {
    padding: 1.2rem;
    ${Text} {
      font-size: 1rem;
    }
    & > ${Button} {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
    }
  }
  ${({ theme }) => theme.media.md.min} {
    ${({ active }) =>
      !active &&
      css`
        transition: 0.25s;
        &:hover {
          border-color: ${({ theme }) => theme.colors.primary};
          box-shadow: 0px 6px 12px #00000014;
        }
      `}
  }
`

const InnerList = styled.div<{ active: boolean }>`
  max-height: 0;
  opacity: 0;
  overflow-y: auto;
  transition: opacity 0.3s ease 0s, max-height 0.3s ease 0s;
  ${({ active }) =>
    active &&
    css`
      max-height: none;
      opacity: 1;
      overflow-y: initial;
      padding: 1.5rem 0 2rem;
      border-top: 1px solid ${({ theme }) => theme.colors.white100};
      margin: 0 2rem;
      ${({ theme }) => theme.media.sm.max} {
        padding: 1.5rem 0 1.5rem;
        margin: 0 1.2rem;
      }
    `}
`

const FAQItem: React.FC<Props> = ({ question, answer, active, toggle }) => {
  return (
    <Wrapper>
      <Header active={active} onClick={toggle}>
        <Text
          size={18}
          themecolor="dark"
          weight={500}
          dangerouslySetInnerHTML={{ __html: question }}
          margin="0"
        />
        <Button as="div" outline={!active} marginLeft round roundSize="50px">
          <Icon src={arrowDown} size={12} white={active} />
        </Button>
      </Header>
      <InnerList active={active}>
        <Content dangerouslySetInnerHTML={{ __html: answer }} />
      </InnerList>
    </Wrapper>
  )
}

export default FAQItem
