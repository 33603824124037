import React, { useState } from 'react'
import styled from 'styled-components'

import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'

import FAQItem, {
  Question,
} from 'components/layout/Sections/Contact/FAQ/SingleItem'

type Props = {
  questions: Question[]
}

const Section = styled.section`
  padding: 4rem 0;
  background: ${({ theme }) => theme.colors.white50};
  ${Row} {
    list-style: none;
  }
  ${Col} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const FAQSection: React.FC<Props> = ({ questions }) => {
  const [activeQuestion, setActiveQuestion] = useState<number | null>(null)

  const toggle = (index: number) => {
    if (activeQuestion === index) setActiveQuestion(null)
    else setActiveQuestion(index)
  }

  return (
    <Section>
      <Container narrow>
        <Row as="ul">
          {questions.map((el, index) => (
            <Col as="li" key={el.question} xs={24}>
              <FAQItem
                question={el.question}
                answer={el.answer}
                active={activeQuestion === index}
                toggle={() => toggle(index)}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default FAQSection
